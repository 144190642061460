<template>
  <LineChartGenerator
    :chart-options="chartOptions"
    :chart-data="data"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="myStyles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";
import merge from "lodash/merge";

export default {
  name: "LineChart",
  components: {
    LineChartGenerator,
  },
  props: {
    chartId: {
      type: String,
      default: "line-chart",
    },
    title: {
      type: String,
      default: "Line Chart",
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    data: {
      type: Object,
      default: () => ({
        labels: ["January", "February", "March"],
        datasets: [
          { label: "Sample", backgroundColor: "#f87979", data: [40, 20, 12] },
        ],
      }),
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: [Number, String],
      default: 400,
    },
    height: {
      type: [Number, String],
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Array,
      default: () => [],
    },
    maintainAspectRatio: {
      type: Boolean,
      required: false,
      default: true,
    },
    responsive: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    myStyles() {
      const responsiveStyle = {
        position: "relative",
      };

      if (typeof this.width === "number") {
        responsiveStyle.width = `${this.width}px`;
      } else if (typeof this.width === "string") {
        responsiveStyle.width = this.width;
      }

      if (typeof this.height === "number") {
        responsiveStyle.height = `${this.height}px`;
      } else if (typeof this.height === "string") {
        responsiveStyle.height = this.height;
      }

      return Object.assign(responsiveStyle, this.styles);
    },
    chartOptions() {
      const options = {
        responsive: this.responsive,
        maintainAspectRatio: this.maintainAspectRatio,
        onClick: this.handleClick,
        plugins: this.plugins,
        indexAxis: this.horizontal ? "y" : "x",
      };

      if (this.title) {
        options.plugins.title = { display: true, text: this.title };
        options.plugins.legend = {
          display: this.showLegend,
        };
      }

      if (this.options) {
        merge(options, this.options);
      }

      return options;
    },
  },
  methods: {
    handleClick(point, event) {
      const item = event[0];
      if (item) {
        const datasetIndex = item.datasetIndex;
        const index = item.index;
        console.log("event", event);
        console.log(item);
        this.$emit("click", {
          datasetIndex,
          index,
          backgroundColor: this.data.datasets[datasetIndex].backgroundColor,
          value: this.data.datasets[datasetIndex].data[index],
          data: this.data.datasets[datasetIndex].dataObject
            ? this.data.datasets[datasetIndex].dataObject[index]
            : null,
        });
      }
    },
  },
};
</script>
